@import '../../../styles/var';

.wrap {
    position: relative;

    .title {

    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, minmax(200px, 314px));
        justify-content: space-between;
        gap: 40px;
        margin-top: 70px;

        @media (max-width: $md4+px) {
            margin-top: 50px;
        }

        @media (max-width: 850px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            margin-top: 30px;
            gap: 30px;
        }

        .card {

            .icon {
                width: 60px;

                @media (max-width: $md4+px) {
                    width: 50px;
                }
            }

            .subtitle {
                font-weight: 700;
                margin: 20px 0 8px;
            }

            .text {

            }
        }
    }
}