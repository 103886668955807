@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 314px));
    justify-content: space-between;
    gap: 50px;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }

    .card {
        max-width: 314px;

        @media (max-width: 900px) {
            text-align: center;
            margin: 0 auto;
        }

        .icon {
            width: 60px;

            @media (max-width: $md6+px) {
                width: 50px;
            }
        }

        .title {
            font-weight: 700;
            margin: 20px 0 8px;
        }

        .text {

        }
    }
}