@import "./_var.scss";
@import './mixins';

body,
html {
    background: #F5F5F5;
    color: #313131;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        background: #000;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #000;
    }
}

.mt {
    margin-top: 180px;

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 100px;
    }
}

.mt-btn {
    margin-top: 40px;
}

.txt-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}