@import '../../../styles/var';

.wrap {
    position: relative;

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        @media(max-width: $md5+px) {
            flex-direction: column;
            align-items: unset;
            justify-content: unset;
            gap: 0;
        }

        .title {}

        .nav {
            display: flex;
            align-items: center;
            gap: 20px;

            @media(max-width: $md5+px) {
                justify-content: flex-end;
            }

            &Btn {
                width: 88px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $violet;
                border-radius: 70px;
                cursor: pointer;
                border: 1px solid $violet;
                transition: opacity 0.3s, border-color 0.3s, background 0.3s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 46px;
                }

                &.left {
                    transform: rotate(180deg);
                }

                &.inactive {
                    opacity: 1 !important;
                    border-color: $dark;
                    background: transparent;

                    .arrow path {
                        stroke: #313131;
                    }
                }

                .arrow path {
                    transition: all .3s;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &Num {
                text-align: center;
            }
        }
    }

    .swiperWrap {
        position: relative;
        overflow: hidden;
        border-radius: 30px;
        background-image: url('../../../assets/img/HomePage/Reviews/bg.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 70px;

        @media(max-width: $md4+px) {
            margin-top: 50px;
        }

        @media(max-width: $md5+px) {
            margin-top: 15px;
        }

        .card {
            height: 100%;
            display: grid;
            grid-template-columns: 255px 1fr;
            gap: 35px;
            padding: 20px;

            @media(max-width: $md4+px) {
                grid-template-columns: 220px 1fr;
            }

            @media(max-width: $md5+px) {
                display: flex;
                flex-direction: column;
                padding-bottom: 30px;
            }

            .image {
                @media(max-width: $md5+px) {
                    width: 100%;
                    max-width: 320px;
                    margin: 0 auto;
                }
                
                img {
                    width: 100%;
                }
            }

            .content {

                .subtitle {

                }

                .text {
                    margin-top: 15px;
                    max-width: 732px;
                }
            }
        }

        .pagination {
            position: absolute;
            z-index: 10;
            bottom: 20px;
            right: 20px;
            display: flex;
            gap: 10px;

            @media(max-width: $md3+px) {
                bottom: 8px;
                right: 40px;
            }

            @media(max-width: $md5+px) {
                bottom: 15px;
                right: 20px;
            }

            .bullet {
                width: 6px;
                height: 6px;
                border-radius: 100%;
                opacity: 0.5;
                background: $dark;
                &Active {
                    opacity: 1;
                }
            }
        }
    }
}

:global(.reviewsSwiper) {
    overflow: hidden !important;

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.reviewsSwiperSlide) {
    height: auto !important;
}