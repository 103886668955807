@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 20px;

    @media(max-width: $md3+px) {
        margin-top: 160px;
    }

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 120px;
    }

    .cards {
        display: grid;
        grid-template-columns: 279px 1fr 383px;
        grid-template-rows: 350px 250px;
        gap: 35px;

        @media(max-width: $md3+px) {
            gap: 20px;
            grid-template-columns: 279px 1fr 340px;
            grid-template-rows: 320px 230px;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 180px 350px;
        }

        @media(max-width: $md6+px) {
            grid-template-rows: auto 146px 300px;
            gap: 18px;
        }

        .card {
            position: relative;
            overflow: hidden;
            padding: 35px;
            border-radius: 30px;

            @media(max-width: $md4+px) {
                padding: 30px;
            }

            @media(max-width: $md6+px) {
                padding: 20px;
                border-radius: 15px;
            }

            .content {
                position: relative;
                z-index: 10;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 10px;
                height: 100%;

                .title {
                    line-height: 110%;
                }

                .text {
                    font-weight: 600;
                    max-width: 488px;
                }
            }

            .image {
                position: absolute;
                z-index: 2;
            }

            &_01 {
                grid-column: 1/3;
                grid-row: 1/2;
                background: #8C83DC;

                .title,
                .text {
                    color: #fff;
                }

                .text {
                    @media(max-width: $md6+px) {
                        max-width: 210px !important;
                    }
                }

                .image {
                    right: 0;
                    bottom: 0;
                    width: 511px;

                    @media(max-width: $md3+px) {
                        width: 360px;
                    }

                    @media(max-width: $md4+px) {
                        width: 250px;
                    }

                    @media(max-width: $md6+px) {
                        width: 230px;
                        right: -60px;
                        opacity: 0.7;
                    }
                }
            }

            &_02 {
                grid-column: 1/1;
                grid-row: 2/2;
                background: #D9D9D9;
            }

            &_03 {
                grid-column: 2/2;
                grid-row: 2/2;
                background: #D1CDFF;

                .image {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 244px;

                    @media(max-width: $md3+px) {
                        width: 170px;
                    }

                    @media(max-width: $md4+px) {
                        width: 150px;
                    }

                    @media(max-width: $md6+px) {
                        width: 120px;
                        opacity: 0.6;
                    }
                }
            }

            &_04 {
                grid-column: 3/3;
                grid-row: 1/3;
                background: #CECECE;

                @media(max-width: $md4+px) {
                    grid-column: 1/3;
                    grid-row: 3/3;
                }

                .content {
                    justify-content: unset;

                    .text {
                        @media(max-width: $md6+px) {
                            max-width: 140px !important;
                        }
                    }
                }

                .image {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 383px;

                    @media(max-width: $md3+px) {
                        width: 300px;
                    }

                    @media(max-width: $md4+px) {
                        width: 270px;
                        left: unset;
                        transform: unset;
                        right: 0;
                    }

                    @media(max-width: $md6+px) {
                        width: 230px;
                    }
                }
            }

            &_02,
            &_03,
            &_04 {

                .title,
                .text {
                    color: $dark;
                }
            }
        }
    }
}