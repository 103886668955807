@import "../../../styles/var";

.container {
    margin-bottom: 30px;

    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }

    @media(max-width: $md6+px) {
        padding: 0 !important;
        margin-bottom: 0;
    }
}

.footerWrap {
    color: #313131;
    border-radius: 30px;
    background: #CECECE;
    padding: 50px 30px;

    @media(max-width: $md6+px) {
        padding: 20px 18px 30px;
        border-radius: 0;
    }
}

.footer {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: 60px;

    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .logo {
        width: 60px;

        @media (max-width: $md3+px) {
            width: 60px;
        }

        @media(max-width: $md6+px) {
            width: 50px;
        }
    }

    .contacts,
    .docs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
            text-decoration: none;
            color: #313131;

            &:hover {
                color: rgba($color: #313131, $alpha: 0.7);
            }
        }
    }

    .about {
        .block {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-family: $sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;

            b {
                font-weight: 500;
            }
        }

        .rights {
            text-align: right;
            margin-top: 20px;
            font-family: $sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}