@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    overflow: hidden;

    gap: 20px;
    padding: 14px 30px;
    background: transparent;
    border-radius: 60px;
    border: 1px solid #313131;

    font-family: $sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #313131;

    @media (max-width: $md6+px) {
        font-size: 14px;
        padding: 10px 22px;
    }

    .arrow {
        position: relative;
        z-index: 1;
        width: 24px;
        height: 24px;

        svg {
            position: relative;
            z-index: 2;
            width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #6248AF;
            border-radius: 100%;
            transition: all .3s;
        }
    }

    span {
        position: relative;
        z-index: 2;
        transition: all .1s;
    }

    &:hover {
        color: #fff;

        .arrow {
            &::before {
                transform: scale(14);
            }
        }
    }

    &:active {
        transform: scale(0.96);
        transition: all 0.1s;
    }

    &.white {
        border-color: #fff;
        color: #fff;

        .arrow {
            &::before {
                background: #fff;
            }

            path {
                stroke: #313131;
            }
        }

        &:hover {
            color: #313131;
    
            .arrow {
                &::before {
                    transform: scale(14);
                }
            }
        }
    }
}