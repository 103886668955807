@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 590px;
    gap: 60px;

    @media(max-width: $md3+px) {
        grid-template-columns: auto 1fr;
        gap: 100px;
    }

    @media(max-width: $md4+px) {
        gap: 60px;
    }

    @media(max-width: 850px) {
        display: flex;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .content {

        .title {
            color: #313131;
        }

        .subtitle {
            margin-top: -16px;

            @media (max-width: $md6+px) {
                margin: -10px 0 -15px;
            }
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 20px;
        display: grid;
        grid-template-rows: repeat(3, 1fr);

        @media(max-width: $md6+px) {
            gap: 10px;
        }

        .card {
            border-radius: 30px;
            background: #CECECE;
            padding: 16px 25px;
            display: grid;
            grid-template-columns: 47px 1fr;
            gap: 30px;
            align-items: center;
            transition: all .2s;
            cursor: pointer;

            @media(max-width: $md6+px) {
                grid-template-columns: 37px 1fr;
                padding: 14px 20px;
                gap: 20px;
            }

            &:hover {
                color: #fff;
                background: $violet;

                .arrow {
                    rect {
                        fill: #fff;
                    }

                    path {
                        stroke: $dark;
                    }
                }
            }

            .arrow {
                width: 47px;
                height: 47px;

                @media(max-width: $md6+px) {
                    width: 37px;
                    height: 37px;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    rect,
                    path {
                        transition: all .2s;
                    }
                }

            }

            &Content {

                .cardTitle {
                    font-weight: 700;
                }

                .cardText {
                    margin-top: 8px;
                    max-width: 260px;

                    @media(max-width: $md4+px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}