@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
        color: #313131;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(4, 175px);
        gap: 40px;
        justify-content: space-around;
        margin-top: 70px;

        @media (max-width: $md4+px) {
            grid-template-columns: repeat(4, 140px);
            margin-top: 50px;
        }

        @media (max-width: $md5+px) {
            grid-template-columns: repeat(2, 140px);
            justify-content: space-evenly;
            gap: 50px 40px;
        }

        @media (max-width: $md6+px) {
            grid-template-columns: repeat(2, 130px);
            justify-content: space-evenly;
            gap: 40px 30px;
            margin-top: 40px;
        }

        .card {
            max-width: 175px;
            width: 100%;

            .image {
                width: 100%;
            }
        }
    }
}