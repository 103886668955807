@import '../../../styles/var';

.wrap {
    position: relative;

    .body {
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 50px;

        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            gap: 30px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media (max-width: $md5+px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }

            .button {
                position: relative;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: space-between;
                cursor: pointer;
                transition: all 0.1s;
                overflow: hidden;

                gap: 20px;
                padding: 14px 30px;
                background: transparent;
                border-radius: 60px;
                border: 1px solid #313131;

                font-family: $sans;
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                color: #313131;

                @media (max-width: $md6+px) {
                    font-size: 14px;
                    padding: 10px 22px;
                }

                &.active {
                    font-weight: 700;

                    .arrow::before {
                        background: #6248AF;
                    }
                }

                .arrow {
                    position: relative;
                    z-index: 1;
                    width: 24px;
                    height: 24px;

                    svg {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #313131;
                        border-radius: 100%;
                        transition: all .3s;
                    }
                }

                span {
                    position: relative;
                    z-index: 2;
                    transition: all .1s;
                }

                &:hover {
                    .arrow {
                        &::before {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }

        .content {
            border-radius: 20px;
            background: #FFF;

            .row {
                display: grid;
                grid-template-columns: calc(40% - 5px) calc(60% - 5px);
                align-items: center;
                gap: 10px;
                width: 100%;
                border-bottom: 1px solid #313131;
                min-height: 50px;
                padding: 10px 15px;

                &:nth-last-child(1) {
                    border-bottom: none;
                }
            }
        }
    }
}