@import '../../../styles/var';

.wrap {
    position: relative;
    border-radius: 50px;
    background: #6248AF;
    padding: 80px 85px;

    @media (max-width: $md3+px) {
        padding: 70px 60px;
    }

    @media (max-width: $md4+px) {
        padding: 50px;
    }

    @media (max-width: 900px) {
        padding: 50px 60px 0;
    }

    @media (max-width: 600px) {
        padding: 50px 30px 0;
        border-radius: 30px;
    }

    @media (max-width: $md6+px) {
        padding: 30px 20px 0;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #fff;
        }

        .text {
            margin-top: 30px;
            color: #fff;
        }

        .btn {

        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 50px;
        width: 406px;

        @media (max-width: $md3+px) {
            width: 350px;
            right: 0;
        }

        @media (max-width: $md4+px) {
            width: 300px;
        }

        @media (max-width: 900px) {
            position: relative;
            display: flex;
            margin: 40px auto 0;
            transform: translateX(25px);
        }

        @media (max-width: 600px) {
            width: 100%;
            max-width: 250px;
        }
    }
}