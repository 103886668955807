@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md3+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;
        
        .title {
            max-width: 700px;

            @media (max-width: $md6+px) {
                text-align: center;
            }
        }

        .subtitle {
            margin: 6px 0 30px;

            @media (max-width: $md6+px) {
                text-align: center;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 30px;
            max-width: 480px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }

            .txt {

            }

            .list {
                display: flex;
                flex-direction: column;
                gap: 15px;
                
                li {
                    display: grid;
                    grid-template-columns: 27px 1fr;
                    align-items: center;
                    gap: 25px;
                }

                .check {
                    width: 100%;
                }
            }
        }
    }

    .btn {
        @media (max-width: $md6+px) {
            text-align: center;
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        top: -10px;
        width: 555px;

        @media (max-width: $md3+px) {
            width: 420px;
            top: unset;
            bottom: 0;
        }

        @media (max-width: $md4+px) {
            position: relative;
            display: flex;
            right: unset;
            bottom: unset;
            margin: 50px auto 0;
            width: 100%;
            max-width: 420px;
        }
        
        @media (max-width: $md6+px) {
            max-width: 300px;
            margin-bottom: 100px;
        }
    }
}