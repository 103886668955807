@import '../../../styles/var';

.wrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: auto 525px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;
        justify-content: unset;
        gap: 50px;
    }

    @media (max-width: $md6+px) {
        gap: 30px;
    }

    .image {
        width: 348px;
        margin-left: 30px;

        @media (max-width: $md3+px) {
            width: 300px;
            margin-left: 0;
        }

        @media (max-width: $md4+px) {
            display: flex;
            margin: 0 auto;
            width: 348px;
        }

        @media (max-width: $md6+px) {
            width: 260px;
        }
    }

    .content {

        .title {

        }

        .subtitle {
            margin: 6px 0 30px;
        }

        .text {

        }
    }
}