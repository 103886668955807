@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }

    .cards {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(3, 255px);
        gap: 40px;
        justify-content: space-around;

        @media (max-width: $md4+px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
        }

        @media (max-width: $md5+px) {
            grid-template-columns: repeat(1, 250px);
            gap: 40px;
        }

        @media (max-width: $md6+px) {
            grid-template-columns: repeat(1, 220px);
            margin-top: 40px;
        }

        .card {
            text-align: center;

            .icon {
                height: 40px;

                @media (max-width: $md6+px) {
                    height: 30px;
                }
            }

            .subtitle {
                font-weight: 700;
                margin: 15px 0 8px;
            }

            .text {

            }
        }
    }
}