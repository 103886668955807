@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 697px;
    justify-content: space-between;
    gap: 60px;

    @media (max-width: $md3+px) {
        grid-template-columns: 1fr 500px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    @media (max-width: $md6+px) {
        gap: 30px;
    }

    .content {

        .title {}

        .subtitle {
            margin: -10px 0 15px;
            color: #313131;

            @media (max-width: $md6+px) {
                margin: -4px 0 15px;
            }
        }

        .text {
            max-width: 380px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }

        .btn {

            @media (max-width: $md4+px) {
                display: none;
            }
        }
    }

    .col {
        padding-top: 40px;

        @media (max-width: $md4+px) {
            padding-top: 0;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
                display: grid;
                grid-template-columns: 27px 1fr;
                align-items: center;
                gap: 25px;
            }

            .check {
                width: 100%;
            }
        }

        .image {
            width: 100%;
            max-width: 697px;
            margin-top: 40px;

            @media (max-width: $md4+px) {
                max-width: 600px;
                display: flex;
                margin: 40px auto 0;
            }
        }
    }

}