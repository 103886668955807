@import '../../../styles/var';

.wrap {
    position: relative;

    .title {}

    .subtitle {
        margin: 15px 0 70px;

        @media(max-width: $md4+px) {
            margin: 10px 0 50px;
        }

        @media(max-width: $md6+px) {
            margin: 5px 0 30px;
        }
    }

    .tabs {
        display: grid;
        grid-template-columns: 350px 1fr;
        gap: 70px;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
        
        @media(max-width: $md6+px) {
            gap: 30px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 12px;

            @media(max-width: $md4+px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width: $md5+px) {
            }

            .button {
                position: relative;
                display: grid;
                grid-template-columns: 1fr 24px;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                transition: all 0.2s;
                overflow: hidden;
            
                gap: 20px;
                padding: 14px 30px;
                background: transparent;
                border-radius: 60px;
                border: 1px solid #313131;
            
                font-family: $sans;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #313131;

                @media(max-width: 600px) {
                    font-size: 14px;
                    padding: 10px 22px;
                }
            
                @media (max-width: $md6+px) {
                    gap: 10px;
                    padding: 10px 18px;
                }
            
                .arrow {
                    position: relative;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
            
                    svg {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                    }
            
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $dark;
                        border-radius: 100%;
                        transition: all .3s;
                    }
                }
            
                span {
                    position: relative;
                    z-index: 2;
                    transition: all .1s;
                }
            
                &:hover {
                    
                }

                &.active {
                    font-weight: 700;
                    .arrow::before {
                        background: $violet;
                    }
                }
            }
        }

        .contentWrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 40px;

            .content {

                &Title {
                    color: $dark;
                }

                &Text {
                    font-weight: 600;
                    margin-top: 15px;
                    max-width: 560px;
                }
            }
        }
    }
}