@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 588px 1fr;
    gap: 35px;
    align-items: center;

    @media(max-width: $md3+px) {
        grid-template-columns: 400px 1fr;
    }

    @media(max-width: $md4+px) {
        display: block;
    }

    .image {
        @media(max-width: $md4+px) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .content {

        .subtitle {
            margin: 15px 0;

            @media(max-width: $md6+px) {
                margin: 5px 0 15px;
            }
        }

        .text {}

        .cards {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            display: grid;
            grid-template-rows: repeat(3, 1fr);

            @media(max-width: $md6+px) {
                gap: 10px;
            }

            .card {
                cursor: pointer;
                border-radius: 30px;
                background: #CECECE;
                padding: 16px 25px;
                display: grid;
                grid-template-columns: 47px 1fr;
                gap: 30px;
                align-items: center;
                transition: all .2s;

                @media(max-width: $md6+px) {
                    grid-template-columns: 37px 1fr;
                    padding: 14px 20px;
                    gap: 20px;
                }

                &:hover {
                    color: #fff;
                    background: $violet;

                    .arrow {
                        rect {
                            fill: #fff;
                        }

                        path {
                            stroke: $dark;
                        }
                    }
                }

                .arrow {
                    width: 47px;
                    height: 47px;

                    @media(max-width: $md6+px) {
                        width: 37px;
                        height: 37px;
                    }

                    svg {
                        width: 100%;
                        height: 100%;

                        rect,
                        path {
                            transition: all .2s;
                        }
                    }

                }

                &Content {

                    .cardTitle {
                        font-weight: 700;
                    }

                    .cardText {
                        margin-top: 8px;
                        max-width: 400px;

                        @media(max-width: $md4+px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}