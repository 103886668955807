@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: 480px calc(100% - 480px - 40px);
    gap: 40px;

    @media(max-width: $md3+px) {
        grid-template-columns: 350px calc(100% - 400px - 40px);
    }

    @media(max-width: $md4+px) {
        display: block;
    }

    .image {

        img {
            width: 100%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;

        .nav {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            &Btn {
                width: 88px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $violet;
                border-radius: 70px;
                cursor: pointer;
                border: 1px solid $violet;
                transition: opacity 0.3s, border-color 0.3s, background 0.3s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 46px;
                }

                &.left {
                    transform: rotate(180deg);
                }

                &.inactive {
                    opacity: 1 !important;
                    border-color: #000;
                    background: transparent;

                    .arrow path {
                        stroke: #000;
                    }
                }

                .arrow path {
                    transition: all .3s;
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &Num {
                text-align: center;
            }
        }

        .swiperWrap {
            width: 100%;
            max-width: 100%;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                margin-top: 25px;
            }

            @media(max-width: $md6+px) {
                margin-top: 15px;
            }

            .card {
                padding: 30px;
                border-radius: 20px;
                height: 100%;

                @media(max-width: $md3+px) {
                    padding: 25px 30px;
                }

                &Title {
                    font-weight: 600;
                }

                &Text {
                    margin-top: 15px;
                    max-width: 490px;

                    @media(max-width: $md3+px) {
                        max-width: 100%;
                    }
                }

                &.light {
                    background: #313131;
                    color: #FFF;
                }

                &.dark {
                    background: #D1CDFF;
                    color: #000;
                }
            }
        }
    }
}

:global(.tradingChooseSwiper) {
    overflow: hidden !important;

    @media(max-width: $md6+px) {
        overflow: visible !important;
    }
}

:global(.tradingChooseSwiperSlide) {
    height: auto !important;
}