@import '../../../styles/var';

.wrap {
    position: relative;
    z-index: 5;

    @media (max-width: $md4+px) {
        margin-top: 140px;
    }

    @media (max-width: $md6+px) {
        margin-top: 120px;
    }

    .card {
        border-bottom: 1px solid #515151;
        transition: all .2s;
        padding: 15px 40px;

        @media(max-width: $md4+px) {
            padding: 15px 30px;
        }

        @media(max-width: $md6+px) {
            padding: 15px 0;
        }

        &.active {
            .content {
                display: flex;
            }

            .button {
                background: #6248AF !important;

                svg {
                    transform: rotate(90deg);

                    path {
                        stroke: #fff;
                    }
                }
            }
        }

        .head {
            display: grid;
            grid-template-columns: 80px 1fr auto;
            align-items: center;
            gap: 20px;

            @media(max-width: $md4+px) {
                grid-template-columns: 60px 1fr auto;
            }

            @media(max-width: $md5+px) {
                grid-template-columns: 1fr auto;
            }

            @media(max-width: $md6+px) {
                gap: 10px;
            }

            .num {
                color: #313131;
                font-size: 33px;
                font-style: italic;
                font-weight: 400;
                line-height: 150%;

                @media(max-width: $md5+px) {
                    display: none;
                }
            }

            .subtitle {
                @media(max-width: $md4+px) {
                    font-size: 20px;
                }

                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }

            .button {
                cursor: pointer;
                position: relative;
                border-radius: 70px;
                border: 1px solid #313131;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 88px;
                height: 56px;
                transition: all .2s;

                @media(max-width: $md4+px) {
                    width: 70px;
                    height: 48px;
                }

                @media(max-width: $md6+px) {
                    width: 56px;
                    height: 36px;
                }

                &:hover {
                    background: rgba($color: #313131, $alpha: 0.1);
                }

                svg {
                    width: 24px;
                    transition: all .2s;

                    @media (max-width: $md4+px) {
                        width: 20px;
                    }

                    @media (max-width: $md6+px) {
                        
                    }

                    * {
                        transition: all .2s;
                    }
                }
            }
        }

        .content {
            display: none;
            margin-top: 10px;
            margin-left: 100px;
            max-width: 940px;

            @media(max-width: $md4+px) {
                margin-left: 80px;
            }

            @media(max-width: $md5+px) {
                margin-left: 0;
            }
        }
    }
}