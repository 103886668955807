@import '../../../styles/var';

.wrap {
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    background: #D1CDFF;
    padding: 40px 70px;

    @media (max-width: $md4+px) {
        border-radius: 30px;
        padding: 40px 50px;
    }

    @media (max-width: $md6+px) {
        padding: 30px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #313131;
            line-height: 140%;
        }

        .text {
            margin-top: 15px;

            @media (max-width: $md3+px) {
                max-width: 320px;
            }

            @media (max-width: 850px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        height: 100%;

        @media (max-width: $md3+px) {
            right: -200px;
        }

        @media (max-width: 850px) {
            opacity: 0.3;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}