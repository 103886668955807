@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 550px;
    justify-content: space-between;
    gap: 60px;

    @media (max-width: $md3+px) {
        grid-template-columns: 1fr 400px;
        align-items: flex-end;
    }

    @media (max-width: $md4+px) {
        display: block;
    }
    

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #313131;

            span {
                color: $violet;
            }
        }

        .subtitle {
            margin: 6px 0 50px;

            @media (max-width: $md4+px) {
                margin-bottom: 40px;
            }

            @media (max-width: $md6+px) {
                margin-bottom: 30px;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .item {
                display: grid;
                grid-template-columns: 70px 1fr;
                align-items: center;
                gap: 32px;

                @media (max-width: $md4+px) {
                    grid-template-columns: 60px 1fr;
                    gap: 26px;
                }

                @media (max-width: $md6+px) {
                    grid-template-columns: 50px 1fr;
                    gap: 20px;
                }

                .num {
                    width: 70px;
                    height: 70px;
                    background: $violet;
                    border-radius: 17px;
                    color: #FFF;
                    font-family: $sans;
                    font-size: 35px;
                    font-weight: 700;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    @media (max-width: $md4+px) {
                        width: 60px;
                        height: 60px;
                        font-size: 28px;
                    }

                    @media (max-width: $md6+px) {
                        width: 50px;
                        height: 50px;
                        font-size: 24px;
                    }
                }

                .text {
                    max-width: 180px;

                    @media (max-width: $md6+px) {
                        max-width: 160px;
                    }
                }
            }
        }
    }

    .image {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 550px;

        @media (max-width: $md4+px) {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 400px;
        }

        @media (max-width: 850px) {
            width: 350px;
        }

        @media (max-width: $md5+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0;
            max-width: 440px;
            width: 100%;
        }

        @media (max-width: $md6+px) {
            max-width: 280px;
            margin-top: 40px;
        }
    }
}