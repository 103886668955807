@import '../../../styles/var';

.container {
    position: relative;
}

.wrap {
    position: relative;
    display: flex;
    align-items: center;
    height: 100vh;
    min-height: 700px;
    max-height: 1000px;

    @media(max-width: $md1+px) {
        max-height: 850px;
    }

    @media(max-width: $md3+px) {
        height: auto;
        min-height: unset;
        max-height: unset;
        padding-top: 140px;
        display: block;
    }

    @media(max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        @media(max-width: $md3+px) {
            text-align: center;
        }

        .title {

            span {
                color: #6248AF;
                font-family: $inter;
                font-size: 100px;
                font-weight: 700;
                line-height: 130%;

                @media(max-width: $md4+px) {
                    font-size: 80px;
                }

                @media(max-width: $md6+px) {
                    font-size: 60px;
                }
            }
        }

        .subtitle {
            margin: 15px 0 50px;
            max-width: 488px;

            @media(max-width: $md3+px) {
                margin: 15px auto 40px;
            }
        }

        .btn {}
    }

    .image {
        position: absolute;
        z-index: 2;
        right: -370px;
        top: 50%;
        transform: translateY(-47%);
        width: 991px;

        @media(max-width: $md1+px) {
            width: 800px;
            right: -200px;
        }

        @media(max-width: $md3+px) {
            right: unset;
            top: unset;
            transform: none;
            position: relative;
            display: flex;
            margin: 50px auto 0;
            max-width: 650px;
            width: 100%;
        }

        img {
            width: 100%;
        }
    }
}