@import '../../../styles/var';

.wrap {
    position: relative;
    border-radius: 50px;
    background: $violet;
    padding: 40px 70px;
    overflow: hidden;

    @media (max-width: $md4+px) {
        border-radius: 30px;
        padding: 40px 50px;
    }

    @media (max-width: $md6+px) {
        padding: 30px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #fff;
            max-width: 490px;
            line-height: 140%;
        }

        .text {
            color: #fff;
            margin-top: 30px;
            max-width: 525px;

            @media (max-width: $md3+px) {
                max-width: 480px;
            }

            @media (max-width: $md4+px) {
                max-width: 100%;
            }

            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        height: 388px;
        right: 0;
        top: calc(50% - 388px / 2);

        @media (max-width: $md3+px) {
            height: 280px;
            top: calc(50% - 280px / 2);            
        }

        @media (max-width: $md4+px) {
            display: none;
        }
    }
}