@import '../../../styles/var';

.container {
    position: relative;
    margin-top: 250px;

    @media (max-width: $md3+px) {
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .image {
        position: absolute;
        z-index: 2;
        width: 867px;
        bottom: -90px;
        right: -10px;
        
        @media (max-width: $md1+px) {
            right: -130px;
        }
        
        @media (max-width: $md2+px) {
            width: 800px;
            bottom: -70px;
        }
        
        @media (max-width: $md3+px) {
            width: 600px;
            bottom: -20px;
        }
        
        @media (max-width: $md4+px) {
            width: 500px;
            bottom: 0px;
        }
        
        @media (max-width: 850px) {
            width: 450px;
            bottom: 0px;
        }
        
        @media (max-width: $md5+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 50px auto 0;
            max-width: 550px;
            width: 80%;
        }
    }
}

.wrap {
    position: relative;

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            margin: 6px 0 30px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            
            li {
                display: grid;
                grid-template-columns: 27px 1fr;
                align-items: center;
                gap: 25px;
            }

            .check {
                width: 100%;
            }
            
            span {
                font-weight: 700;
            }
        }
    }
}