@import '../../../styles/var';

.wrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 525px auto;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: 50px;
    }

    @media (max-width: $md6+px) {
        gap: 40px;
    }

    .content {

        .title {
            color: #313131;
        }

        .text {
            margin-top: 30px;
        }
    }

    .image {
        width: 371px;
        margin-right: 90px;

        @media (max-width: $md3+px) {
            width: 300px;
            margin-right: 0;
        }

        @media (max-width: $md4+px) {
            display: flex;
            margin: 0 auto;
            width: 320px;
        }

        @media (max-width: $md6+px) {
            width: 240px;
        }
    }
}