@import '../../../styles/var';

.wrap {
    position: relative;

    .content {
        position: absolute;
        z-index: 5;
        top: 0;
        right: 0;
        width: 450px;

        @media (max-width: $md3+px) {
            position: relative;
            margin-bottom: 40px;
        }

        @media (max-width: $md4+px) {
            width: 100%;
        }

        .title {
            color: #313131;

            span {
                color: $violet;
            }
        }

        .btn {
            @media (max-width: $md3+px) {
                display: none;
            }
        }
    }

    .cards {
        position: relative;
        z-index: 5;
        display: grid;
        grid-template-columns: repeat(2, 315px);
        grid-template-rows: repeat(1, 285px);
        gap: 60px;

        @media (max-width: 850px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 245px);
            gap: 40px;
        }

        @media (max-width: $md5+px) {
            gap: 20px;
            grid-template-rows: repeat(1, auto);
        }

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

        &_01 {}

        &_02 {
            justify-content: flex-end;
            margin-top: 40px;

            @media (max-width: $md5+px) {
                margin-top: 20px;
            }
        }

        .card {
            border-radius: 30px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (max-width: $md5+px) {
                padding: 30px 20px;
            }

            &_01 {
                background: #D9D9D9;
            }

            &_02 {
                background: #8C83DC;
                color: #fff;
            }

            &_03 {
                background: #313131;
                color: #fff;
            }

            &_04 {
                background: #D1CDFF;
            }

            .icon {
                width: 39px;

                @media (max-width: $md6+px) {
                    width: 35px;
                }
            }

            .subtitle {
                font-weight: 700;
                margin: 12px 0 8px;
                max-width: 200px;

                @media (max-width: $md4+px) {
                    max-width: 180px;
                }

                @media (max-width: $md6+px) {
                    max-width: 170px;
                }
            }

            .text {}
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        width: 342px;
        bottom: -160px;
        left: 50px;

        @media (max-width: $md3+px) {
            left: 0;
            width: 280px;
            bottom: -140px;            
        }

        @media (max-width: $md4+px) {
            display: none;         
        }
    }
}