@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 250px;

    @media (max-width: $md3+px) {
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    
    .bg {
        position: absolute;
        z-index: 2;
        bottom: -130px;
        right: 0;
        width: 1674px;

        @media (max-width: $md3+px) {
            width: 1200px;
        }

        @media (max-width: $md4+px) {
            width: 1000px;
            bottom: -80px;
        }

        @media (max-width: $md5+px) {
            width: 800px;
            bottom: 0;
        }

        @media (max-width: $md6+px) {
            width: 600px;
            bottom: 10px;
        }
    }
}

.body {
    position: relative;
    padding-bottom: 60px;

    @media (max-width: $md4+px) {
        padding-bottom: 0;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            margin: 6px 0 30px;
        }

        .text {
            max-width: 488px;

            @media (max-width: $md3+px) {
                max-width: 440px;
            }

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        width: 540px;

        @media (max-width: $md3+px) {
            width: 470px;
        }

        @media (max-width: $md4+px) {
            width: 100%;
            max-width: 450px;
            position: relative;
            display: flex;
            margin: 50px auto 0;
        }

        @media (max-width: $md6+px) {
            max-width: 280px;
        }
    }
}