@import '../../../styles/var';

.wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .grid {
        display: grid;
        grid-template-columns: 497px 1fr;
        gap: 20px;

        @media (max-width: $md3+px) {
            grid-template-columns: 400px 1fr;
        }

        @media (max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;

            .image {
                width: 100%;
                max-width: 350px;
                display: flex;
                margin: 0 auto;
            }

            .text {
                max-width: 100%!important;
            }
        }

        &.gridHead {
            @media (max-width: $md4+px) {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }
        }
    }

    .head {

        .ttl_02 {
            margin-top: 6px;
        }
    }

    .card {
        border-radius: 30px;
        padding: 40px;

        @media (max-width: $md3+px) {
            padding: 30px;
        }

        @media (max-width: $md6+px) {
            padding: 20px;
            border-radius: 20px;
        }

        &_01 {
            background: #FFF;
            display: grid;
            grid-template-columns: auto 239px;
            gap: 35px;

            @media (max-width: $md3+px) {
                grid-template-columns: auto 180px;
                gap: 35px;
                align-items: center;
            }
            @media (max-width: $md5+px) {
                display: block;
                .image {
                    display: none;
                }
            }
        }

        &_02 {
            background: #D9D9D9;
            display: grid;
            grid-template-columns: auto 457px;
            gap: 35px;

            @media (max-width: $md3+px) {
                grid-template-columns: auto 400px;
                gap: 30px;
                align-items: center;
            }

            @media (max-width: $md4+px) {
                grid-template-columns: auto 300px;
            }

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column;
                align-items: unset;

                .image {
                    max-width: 400px;
                    width: 100%;
                    display: flex;
                    margin: 0 auto;
                }
            }
        }

        &_03 {
            background: #D9D9D9;

            .text {
                margin-top: 25px;
            }
        }

        &_04 {
            background: #FFF;

            .text {
                margin-top: 25px;
            }
        }

        &_05 {
            background: #313131;
            display: grid;
            grid-template-columns: auto 457px;
            gap: 35px;

            @media (max-width: $md3+px) {
                grid-template-columns: auto 400px;
                gap: 30px;
                align-items: center;
            }

            @media (max-width: $md4+px) {
                grid-template-columns: auto 300px;
            }

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column;
                align-items: unset;

                .image {
                    max-width: 400px;
                    width: 100%;
                    display: flex;
                    margin: 0 auto;
                }
            }

            .subtitle {
                color: #fff;
            }

            .text {
                color: #fff;
            }
        }

        &_06 {
            background: #FFF;
            display: grid;
            grid-template-columns: 457px auto;
            gap: 35px;

            @media (max-width: $md3+px) {
                grid-template-columns: 400px auto;
                gap: 30px;
                align-items: center;
            }

            @media (max-width: $md4+px) {
                grid-template-columns: 300px auto;
            }

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column-reverse;
                align-items: unset;

                .image {
                    max-width: 400px;
                    width: 100%;
                    display: flex;
                    margin: 0 auto;
                }
            }
        }

        .subtitle {
            margin-bottom: 30px;

            @media (max-width: $md4+px) {
                margin-bottom: 20px;
            }
        }

        .text {
            max-width: 450px;
        }

        .image {
            width: 100%;
        }
    }
}