@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 230px;
    margin-bottom: 180px;

    @media (max-width: $md1+px) {
        margin-top: 210px;
    }

    @media (max-width: $md3+px) {
        margin-top: 160px;
        margin-bottom: 0;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            color: #313131;
            margin: -5px 0 30px;

            @media (max-width: $md6+px) {
                margin-top: 0;
            }
        }
        
        .text {
            max-width: 525px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: -80px;
        width: 551px;

        @media (max-width: $md1+px) {
            width: 500px;
            bottom: -50px;
        }

        @media (max-width: $md3+px) {
            width: 400px;
            bottom: -10px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 30px auto 0;
            width: 430px;
        }

        @media (max-width: $md6+px) {
            width: 300px;
            margin-top: 20px;
        }
    }
}