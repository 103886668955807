@import './var';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.font-103 {
    color: $violet;
    font-family: $inter;
    font-size: 103px;
    font-weight: 700;
    line-height: 130%;

    @media(max-width: $md4+px) {
        font-size: 80px;
    }

    @media(max-width: $md6+px) {
        font-size: 57px;
    }
}

.font-70 {
    color: #6248AF;
    font-family: $inter;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    @media(max-width: $md4+px) {
        font-size: 50px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-70-45 {
    color: #313131;
    font-family: $inter;
    font-size: 70px;
    font-weight: 700;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 56px;
    }

    @media(max-width: $md6+px) {
        font-size: 45px;
    }
}

.font-55 {
    color: $violet;
    font-family: $inter;
    font-size: 55px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 42px;
    }

    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-40 {
    color: #313131;
    font-family: $sans;
    font-size: 40px;
    font-weight: 400;
    line-height: 120%;

    @media(max-width: $md3+px) {
        font-size: 34px;
    }

    @media(max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-25 {
    color: #313131;
    font-family: $sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 22px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-22 {
    color: #313131;
    font-family: $sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-17 {
    color: #313131;
    font-family: $sans;
    font-size: 17px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16 {
    color: #313131;
    font-family: $sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}