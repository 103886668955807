@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 230px;

    @media (max-width: $md1+px) {
        margin-top: 210px;
    }

    @media (max-width: $md3+px) {
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            color: #313131;
            margin: -5px 0 30px;

            @media (max-width: $md6+px) {
                margin-top: 0;
            }
        }
        
        .text {
            max-width: 525px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: -20px;
        width: 524px;

        @media (max-width: $md1+px) {
            width: 500px;
        }

        @media (max-width: $md3+px) {
            width: 400px;
            bottom: -10px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 50px auto 0;
            width: 400px;
        }

        @media (max-width: $md6+px) {
            width: 280px;
            margin-top: 40px;
        }
    }
}