@import '../../../styles/var';

.wrap {
    position: relative;

    .title {

    }

    .subtitle {
        margin: 15px 0 70px;

        @media(max-width: $md4+px) {
            margin: 10px 0 50px;
        }

        @media(max-width: $md6+px) {
            margin: 5px 0 40px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, minmax(180px, 314px));
        justify-content: space-between;
        gap: 70px 50px;

        @media(max-width: $md4+px) {
            gap: 50px 40px;
        }

        @media(max-width: 850px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        @media(max-width: $md6+px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .card {

            &Icon {
                width: 60px;

                @media(max-width: $md4+px) {
                    width: 50px;
                }
            }

            &Title {
                margin: 20px 0 8px;
                font-weight: 700;
            }

            &Text {

            }
        }
    }
}